import { h, render } from 'preact';
import { isMouseDownRecently } from 'utilities/isMouseDown.js';
import { Color } from 'utilities/color.js';
import { unescapeHtml } from 'utilities/core.js';
import {
  getCssTags,
  execCssTags,
  removeCssTags,
  getScriptTags,
  execScriptTags,
  removeScriptTags,
  elemStripEventAttributes,
} from 'utilities/elem.js';
import { countMetric } from 'utilities/simpleMetrics.js';
import { dynamicImport } from 'utilities/dynamicImport.ts';
import { Wistia } from '../../../../../../../wistia_namespace.ts';
import { CTAOverlay } from '../../ui_components/CTAOverlay.jsx';
import { maybeLoadAndSetupEmbedLinksThrottled } from '../../../../../modules/_embed_links.js';

const PLUGIN_NAME = 'postRoll-v2-cta';

class PostRollPlugin extends Wistia.Plugin.Base {
  constructor(video, options) {
    super(video, options);
    this.pluginName = 'postRoll-v2';
    this.video = video;
    this.options = options;
    this.updateFromOptions(this.options);
    this._conversionOpportunityType = 'callToAction';
    this._isVisible = false;
    this.unbinds = [];
    this.boundEnsurePauseOnShowInCustomizePreviewMode =
      this.ensurePauseOnShowInCustomizePreviewMode.bind(this);

    if (this._time == null) {
      this._time = 'end';
    }

    if (!this._style) {
      this._style = {};
    }

    if (this._scriptTags?.length > 0) {
      countMetric('cta-scripts', 1, {
        script: this._scriptTags,
        url: window.location.href,
      });
    }

    this.video.defineOverlay(PLUGIN_NAME, this.getOptionsForDefineOverlay());
    this.setupOverlayBindings();
  }

  renderCta() {
    return new Promise((resolve) => {
      const options = {
        altText: this._altText,
        ctaType: this._ctaType,
        text: this._text,
        raw: this._raw,
        image: this._image,
        time: this._time,
        rewatch: this._rewatch,
        autoSize: this._autoSize,
        style: this._style,
        link: this._link,
      };
      const video = this.video;
      const isMostRecentFocusViaMouse = video.controls.modalOverlay.props.isMostRecentFocusViaMouse;

      dynamicImport('assets/external/interFontFace.js').then(() => {
        render(
          <CTAOverlay
            options={options}
            playerLanguage={video.playerLanguage()}
            videoHeight={video.videoHeight()}
            videoWidth={video.videoWidth()}
            isMostRecentFocusViaMouse={isMostRecentFocusViaMouse}
            onClickSkip={this.onClickSkip}
            onClickRewatch={this.onClickRewatch}
            nextVideo={this.nextVideo}
            logConversionEvent={this.logConversionEvent}
            hoverColor={this.translateColor().rgbaHoverColor}
            uiContainer={video.uiContainer}
          />,
          this.rootElem,
        );
        this.reactMounts = [this.rootElem];
        resolve();
      });
    });
  }

  customizePreview(changeSet) {
    this._isInCustomizePreviewMode = true;

    if (
      !changeSet.anyChanged([
        'plugin[postRoll-v2]',
        'plugin[postRoll-v1]',
        'ephemeral[ui][callToAction]',
      ])
    ) {
      return;
    }

    this.resetOverLayType();
    const opts = changeSet.currentValue('plugin[postRoll-v2]') ||
      changeSet.currentValue('plugin[postRoll-v1]') || { on: false };

    const currentPluginOptions = { ...opts };

    this._isEnabled = currentPluginOptions.on !== false;

    // for ctas of type text, we want to load the plugin with some
    // default text and link.
    if (currentPluginOptions.ctaType === 'text') {
      if (!currentPluginOptions.text) {
        currentPluginOptions.text = "Here's a link to click! →";
      }

      if (!currentPluginOptions.link) {
        currentPluginOptions.link = 'https://wistia.com';
      }
    }

    this.updateFromOptions(currentPluginOptions);
    // Reset the crosstime binding to ensure the overlay shows at the correct
    // time during playback or on seek
    this.unbinds?.map((e) => e());
    this.unbinds = [];
    this.setupOverlayBindings();

    if (changeSet.currentValue('ephemeral[ui][callToAction][isOpen]') && this._isEnabled) {
      this.video.ready(() => {
        this.video.time(this.formatStringToTime(currentPluginOptions.time)).then(() => {
          if (!this._isEnabled || this._isSuppressed) {
            return;
          }
          this.show();
        });
      });
    } else {
      this.hide();
    }
  }

  suppress() {
    this._isSuppressed = true;
    return this.hide();
  }

  unsuppress() {
    this._isSuppressed = false;
  }

  formatStringToTime(time) {
    let translateTime;
    if (time === 'before') {
      translateTime = 0;
    } else if (time === 'end') {
      translateTime = this.video.duration();
    } else {
      translateTime = time;
    }

    return translateTime;
  }

  // Returns an object that will be passed when `defineOverlay` is called.
  // Allows the plugin to call things like renderCta on the overlay.
  getOptionsForDefineOverlay() {
    const plugin = this;
    return {
      mount(rootElem) {
        plugin.rootElem = rootElem;
        return plugin.renderCta().then(() => {
          plugin.possiblyExecuteScriptTags(true);
        });
      },
      onControlPropsUpdated(prevProps) {
        if (prevProps.videoWidth !== this.props.videoWidth) {
          plugin.renderCta();
        }

        if (
          prevProps.playerLanguage &&
          prevProps.playerLanguage.code !== this.props.playerLanguage.code
        ) {
          plugin.renderCta();
        }
      },
      style: {
        ...plugin._style,
        backgroundColor: plugin.translateColor().rgbaBackgroundColor,
      },
      type: 'modal',
      transition: 'fade',
    };
  }

  nextVideo = () => {
    return this.video.nextVideo();
  };

  translateColor = () => {
    const defaultColor = new Color('#303030');
    const backgroundColor = defaultColor.alpha(this._style.backgroundOpacity || 0.91);
    const hoverColor = new Color(backgroundColor);

    const rgbaBackgroundColor = backgroundColor.toRgba();
    const rgbaHoverColor = hoverColor.alpha(0.41).toRgba();
    return {
      rgbaBackgroundColor,
      rgbaHoverColor,
    };
  };

  // set up the bindings on when the overlay should appear
  setupOverlayBindings() {
    // wait for the video to be embedded before we actually try and
    // render anything out
    this.video.embedded(() => {
      const time = this._time;
      if (time === 'before' && this.video.state() === 'beforeplay') {
        this.show();
      } else if (time === 'end') {
        this.unbinds.push(
          this.video.on('end', () => {
            this.show();
          }),
        );
      } else {
        this.unbinds.push(
          this.video.on('crosstime', this._time, () => {
            this.show();
          }),
        );
      }

      // when videos rebuild, we need to ensure we're also setting
      // the CTA visibility back to false
      this.unbinds.push(
        this.video.on('beforerebuild', () => {
          this._isVisible = false;
        }),
      );
    });
  }

  onClickSkip = () => {
    // we should start the video if we are in a beforeplay state and skip
    if (this.video.state() === 'beforeplay') {
      this.video.unsuspend({ state: 'playing', time: this.video.time() });
    }

    this.hide();

    if (!isMouseDownRecently()) {
      this.video._focusNextVisibleElem();
    }
  };

  onClickRewatch = () => {
    this.video.unsuspend({ time: 0, state: 'playing' });
    this.hide();

    if (!isMouseDownRecently()) {
      this.video._focusNextVisibleElem();
    }
  };

  hide() {
    this._isVisible = false;
    return this.video.cancelOverlay(PLUGIN_NAME);
  }

  sanitizeUrl(unsanitizedUrl) {
    const url = unescapeHtml(unsanitizedUrl);
    if (/^(https?|mailto|ftp|tel|#|\/)/.test(url)) {
      return url;
    }
    return `http://${url}`;
  }

  sanitizeRaw(html) {
    // save the script tags and unstripped raw to be executed later if allowed
    this._previousUnstrippedRaw = this._unstrippedRaw || html;
    this._unstrippedRaw = html;
    this._scriptTags = getScriptTags(html);
    this._cssTags = getCssTags(html);

    return removeCssTags(removeScriptTags(html));
  }

  formatConversionData() {
    let time;
    if (this._time === 'end') {
      time = this.video.duration();
    } else if (this._time === 'before') {
      time = 0;
    } else {
      time = this._time;
    }

    return {
      co_key: this._conversionOpportunityKey,
      co_type: this._conversionOpportunityType,
      time,
    };
  }

  // showing a CTA logs a conversion _opportunity_
  logConversionOpportunity() {
    this.video._tracker.logConversionOpportunity(this.formatConversionData());
  }

  // clicking on a CTA logs the conversion
  logConversionEvent = () => {
    const conversionData = {
      ...this.formatConversionData(),
      converted: true,
      link: this._link,
    };

    this.video.trigger('conversion-postRoll', conversionData);
    this.video._tracker.logConversionOpportunity(conversionData);
  };

  ensurePauseOnShowInCustomizePreviewMode() {
    if (this._isVisible) {
      this.video.pause();
    }
    this.video.off('play', this.boundEnsurePauseOnShowInCustomizePreviewMode);
  }

  show() {
    if (this._isSuppressed) {
      return Promise.resolve();
    }

    // This whole block is a hack to get around the fact that if you click in
    // the player's playbar past the time of the CTA, before the CTA has shown,
    // the document mouseup binding from the playbar will cause the video to
    // play (behind the CTA overlay) after the CTA is shown.
    if (this._isInCustomizePreviewMode) {
      // We pause here because when this._isInCustomizePreviewMode, we make it
      // so showing a the overlay does not suspend the player, and suspend is
      // what calls pause() normally.
      this.video.pause();
      const unbind = this.video.on('play', this.boundEnsurePauseOnShowInCustomizePreviewMode);
      this.unbinds.push(unbind);
    }

    return this.video
      .requestOverlay(PLUGIN_NAME, { shouldSuspendPlayer: !this._isInCustomizePreviewMode })
      .then(() => {
        return this.renderCta();
      })
      .then(() => {
        if (!this._isVisible) {
          this._isVisible = true;
          // only want to log that a conversion opportunity has been presented if we're going
          // from not being visible to visible
          this.logConversionOpportunity();
        }

        this.possiblyExecuteScriptTags();

        // always ensure that embed links are set up properly
        if (this.rootElem) {
          maybeLoadAndSetupEmbedLinksThrottled(this.rootElem);
          // Strip attributes like 'onmouseover' from the root and all its children
          // to prevent XSS attacks. This is only relevant on wistia.com domains.
          if (!Wistia.plugin._allow3rdParty(this.video.embedOptions())) {
            elemStripEventAttributes(this.rootElem);
          }
        }
      });
  }

  // if you have raw html with scriptTags and allow3rdParty as an option
  // we will execute your scripts under certain conditions
  possiblyExecuteScriptTags(firstRender) {
    const firstRenderOrRawChanged =
      this._unstrippedRaw !== this._previousUnstrippedRaw || firstRender;

    // you need to have raw, allow3rdParty, script tags, and raws needs to have changed or be first render
    const hasScriptTags = this._scriptTags && this._scriptTags.length > 0;
    const hasCssTags = this._cssTags && this._cssTags.length > 0;
    if (this._raw && (hasScriptTags || hasCssTags) && firstRenderOrRawChanged) {
      if (Wistia.plugin._allow3rdParty(this.video.embedOptions())) {
        execScriptTags(this._scriptTags || []);
        execCssTags(this._cssTags || []);
      }
    }
  }

  remove() {
    this.unbinds.map((e) => e());
    this.unbinds = [];
    this.video.cancelOverlay(PLUGIN_NAME);
    this._isInCustomizePreviewMode = false;
    super.remove();
  }

  resetOverLayType() {
    this._text = undefined;
    this._image = undefined;
    this._html = undefined;
    this._raw = undefined;
  }

  // using the same method names as in _call_to_action.coffee
  updateText(text) {
    this.resetOverLayType();
    this._text = text;
    this.show();
  }

  // using the same method from _call_to_action.coffee
  updateImage(image) {
    this.resetOverLayType();
    this._image = preloadImg(image);
    this.show();
  }

  updateRaw(html) {
    this.resetOverLayType();
    this._raw = this.sanitizeRaw(html);
    this.show();
  }

  updateUrl(unsanitizedUrl) {
    this._link = this.sanitizeUrl(unsanitizedUrl);
    this.show();
  }

  showRewatch() {
    this._rewatch = true;
    this.show();
  }

  hideRewatch() {
    this._rewatch = false;
    this.show();
  }

  updateFromOptions(options) {
    for (const prop in options) {
      this.updateFromOption(`_${prop}`, options[prop]);
    }
  }

  updateFromOption(optionName, optionValue) {
    switch (optionName) {
      case '_link':
        this._link = this.sanitizeUrl(optionValue);
        break;
      case '_raw':
        this._raw = this.sanitizeRaw(optionValue);
        break;
      case '_image':
        this._image = preloadImg(optionValue);
        break;
      case '_backgroundOpacity':
        this._style = {
          ...this._style,
          backgroundOpacity: optionValue,
        };
        break;
      default:
        this[optionName] = optionValue;
    }
  }
}

const preloadImg = (src) => {
  if (!src) {
    return;
  }
  const image = new Image();
  image.src = src;
  return image;
};

Wistia.plugin('postRoll-v2', (video, options) => {
  return new PostRollPlugin(video, options);
});
